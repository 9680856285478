import { FC, useState } from "react";

import { AudienceSplit } from "@hightouch/lib/query/visual/types";
import { Row, Link, Text, DestinationBadge, Tooltip, Button, Column } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";

import placeholder from "src/assets/placeholders/sync.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { ModelQuery, ResourcePermissionGrant } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { DestinationMetadataDrawer, processAudienceSize } from "src/pages/syncs/external-data";
import { isPresent } from "src/types/utils";
import { ExternalLinkIcon } from "src/ui/icons";
import { Table, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { getObjectName, SyncStatusBadge } from "src/utils/syncs";
import { formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { DraftBadge } from "../drafts/draft-badge";
import { Permission } from "../permission";

type Props = {
  isAudience?: boolean;
  syncs?: (NonNullable<ModelQuery["segments_by_pk"]>["syncs"][0] & { destination_metadata?: any })[];
  onAdd: () => void;
};

export const Syncs: FC<Readonly<Props>> = ({ isAudience, syncs, onAdd }) => {
  const navigate = useNavigate();
  const { data: entitlementsData } = useEntitlements(true);
  const { appDisableSyncCreationForAudiences } = useFlags();

  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a sync, upgrade your plan.";

  const [selectedSyncId, setSelectedSyncId] = useState();
  const selectedSync = syncs?.find((s) => s.id === selectedSyncId);

  const showSplitsColumn = isAudience;
  const showExternalColumn = isAudience && syncs?.length && syncs?.some((s) => s.destination_metadata);

  const columns: TableColumn[] = [
    {
      name: "Status",
      min: "130px",
      max: "130px",
      cell: ({ status, sync_requests, draft: isInitialDraft }) => {
        if (isInitialDraft) {
          return <DraftBadge />;
        }

        const syncRequest = sync_requests?.[0];
        const request = syncRequest ? syncRequest : { status_computed: status };
        return <SyncStatusBadge request={request} status={status} />;
      },
    },
    {
      name: "Destination",
      max: "1fr",
      cell: ({ config, destination: { name, definition } }) => {
        return (
          <Row align="center" gap={3} overflowX="hidden">
            <IntegrationIcon name={definition?.name} src={definition?.icon} />
            <Column gap={1} overflow="hidden">
              <TextWithTooltip fontWeight="medium">{name ?? definition?.name ?? "Private destination"}</TextWithTooltip>
              {config?.object && <DestinationBadge>{getObjectName(config.object)}</DestinationBadge>}
            </Column>
          </Row>
        );
      },
    },
    {
      name: "Description",
      max: "1.5fr",
      cell: ({ description }) => description || "--",
    },
    {
      name: "Last run",
      max: "max-content",
      cell: ({ id, sync_requests }) => {
        const syncRequest = sync_requests?.[0];
        return syncRequest ? (
          <>
            <Link href={`/syncs/${id}/runs/${syncRequest?.id}`}>
              <Text fontWeight="medium">{formatDatetime(syncRequest?.created_at)}</Text>
            </Link>
          </>
        ) : null;
      },
    },
    !!showExternalColumn && {
      name: "Matched users",
      cell: ({ id, destination_metadata }) => {
        if (destination_metadata?.audienceSize) {
          return (
            <Row
              align="center"
              gap={2}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedSyncId(id);
              }}
            >
              {processAudienceSize(destination_metadata.audienceSize)}
              {Boolean(destination_metadata?.audience?.url) && (
                <Row sx={{ ":hover": { color: "text.secondary" } }}>
                  <ExternalLinkIcon size={14} />
                </Row>
              )}
            </Row>
          );
        }
        return "--";
      },
    },
    {
      ...LastUpdatedColumn,
    },
    !!showSplitsColumn && {
      name: "Splits",
      max: "1fr",
      cell: ({ destination_instance_splits }) => {
        if (destination_instance_splits.length === 0) {
          return "--";
        }

        const splitsText = destination_instance_splits.map(
          ({ split }: { split: AudienceSplit }) => `${split.friendly_name} (${split.percentage}%)`,
        );

        return <Row>{splitsText.join(", ")}</Row>;
      },
    },
  ].filter(isPresent);

  return (
    <Row width="100%">
      <Table
        columns={columns}
        data={syncs}
        placeholder={{
          title: `This ${isAudience ? "audience" : "model"} is not syncing to any destinations`,
          body: "Still working on this? Add a sync when you’re ready",
          image: placeholder,
          button: (
            <>
              {(!isAudience || !appDisableSyncCreationForAudiences) && (
                <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                  <Tooltip message={overageText} isDisabled={!overageLockout}>
                    <Button isDisabled={overageLockout} onClick={() => onAdd()}>
                      Add a sync
                    </Button>
                  </Tooltip>
                </Permission>
              )}
            </>
          ),
        }}
        onRowClick={({ id }, event) => openUrl(`/syncs/${id}`, navigate, event)}
      />
      <DestinationMetadataDrawer
        metadata={selectedSync?.destination_metadata}
        isOpen={Boolean(selectedSync)}
        onClose={() => {
          setSelectedSyncId(undefined);
        }}
        definition={selectedSync?.destination?.definition}
      />
    </Row>
  );
};
