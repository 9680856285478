import { useEffect, useState, ReactNode, FC } from "react";

import { Checkbox, useToast } from "@hightouchio/ui";
import Papa from "papaparse";
import Dropzone, { FileRejection } from "react-dropzone";

import {
  BooleanOperator,
  ColumnDateTypes,
  ColumnType,
  MultiValueColumnTypes,
  MultiValueOperators,
  OperatorsWithoutValue,
  PercentileOperators,
} from "src/types/visual";
import { Box } from "src/ui/box";
import { NewSelect } from "src/ui/new-select";
import { CreatableMultiSelect, CreatableSelect } from "src/ui/select";

import { AmountInput } from "./amount-input";
import { PropertyFilterProps } from "./property-filter";
import { TimestampInput } from "./timestamp-input";

export type PropertyInputProps = PropertyFilterProps & {
  loading?: boolean;
  suggestions: Array<{ value: string | number | null; count: number | undefined }> | undefined | null;
  error?: ReactNode;
};

export const PropertyInput: FC<Readonly<PropertyInputProps>> = ({ condition, error, loading, suggestions, onChange }) => {
  const { toast } = useToast();

  const suggestionOptions = suggestions?.map(({ value }) => ({ label: String(value), value }));

  const [multiSelectOptions, setMultiSelectOptions] = useState(suggestionOptions || []);

  useEffect(() => {
    setMultiSelectOptions(suggestionOptions || []);
  }, [condition.property, suggestions?.length]);

  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }

  if (
    MultiValueOperators.includes(condition.operator) &&
    condition.propertyType &&
    MultiValueColumnTypes.includes(condition.propertyType)
  ) {
    const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        toast({
          id: "file-upload",
          title: 'Each file must be in ".csv" or ".txt" format and uploaded one at a time',
          variant: "error",
        });
      }

      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];

        if (file) {
          Papa.parse(file, {
            complete: (results) => {
              const values = results.data.flat();

              toast({
                id: "file-upload",
                title: `Values from '${file.name}' were added`,
                variant: "success",
              });

              setMultiSelectOptions([...multiSelectOptions, ...values.map((value) => ({ label: value, value }))]);
              onChange({ value: [...(condition.value || []), ...values] });
            },
          });
        }
      }
    };

    // TODO: show error on dropzone component
    return (
      <Dropzone
        accept={[".csv", ".txt"]}
        maxFiles={1}
        multiple={false}
        noClick={true}
        noKeyboard={true}
        onDrop={handleFileDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Box {...getRootProps()}>
              <CreatableMultiSelect
                {...getInputProps()}
                isLoading={loading}
                options={multiSelectOptions}
                placeholder={isDragActive ? "Upload your file here..." : "Add values or drag/drop a text file..."}
                value={condition.value || []}
                width="300px"
                onChange={(options) => {
                  const values = options.map((option) => option.value);
                  onChange({ value: values });
                }}
                onCreateOption={(v) => {
                  setMultiSelectOptions([...multiSelectOptions, { label: v, value: v }]);
                  onChange({ value: [...(condition.value || []), v] });
                }}
              />
            </Box>
          );
        }}
      </Dropzone>
    );
  }

  if (condition.propertyType && !ColumnDateTypes.includes(condition.propertyType) && suggestions) {
    return (
      <CreatableSelect
        isClearable
        isLoading={loading}
        formatCreateLabel={(string) => string}
        options={suggestionOptions ?? []}
        placeholder="search..."
        tip={`${suggestionOptions?.length ?? 0} suggested values. Type to use a custom value.`}
        value={condition.value}
        width="200px"
        onChange={(option) => onChange({ value: option?.value })}
        onCreateOption={(value) => onChange({ value })}
      />
    );
  }

  if (condition.propertyType === ColumnType.Boolean) {
    return (
      <NewSelect
        error={Boolean(error)}
        options={[
          {
            value: true,
            label: "True",
          },
          {
            value: false,
            label: "False",
          },
        ]}
        loading={loading}
        placeholder="True / False"
        value={condition.value}
        width={100}
        onChange={(value) => {
          onChange({
            operator: BooleanOperator.Equals,
            value,
          });
        }}
      />
    );
  }

  if (condition.propertyType && ColumnDateTypes.includes(condition.propertyType)) {
    return (
      <TimestampInput
        condition={condition}
        error={error}
        hideTime={condition.propertyType === ColumnType.Date}
        onChange={onChange}
      />
    );
  }

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <AmountInput
        error={error}
        type={
          condition.propertyType && [ColumnType.Number, ColumnType.JsonArrayNumbers].includes(condition.propertyType)
            ? "number"
            : "string"
        }
        value={condition.value}
        onChange={(value) => {
          onChange({ value });
        }}
      />
      {condition.propertyType === ColumnType.Number && PercentileOperators.includes(condition.operator) && (
        <Box py="6px">
          <Checkbox
            label="Percentile"
            isChecked={Boolean(condition.propertyOptions?.percentile)}
            onChange={(event) => {
              onChange({
                propertyOptions: {
                  ...condition.propertyOptions,
                  percentile: event.target.checked,
                },
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};
