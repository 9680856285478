import { ChangeEventHandler, useEffect, useMemo, useState } from "react";

import { sortBy } from "lodash";

import {
  ColumnType,
  Condition,
  ConditionType,
  getInitialTraitColumn,
  isRelatedColumn,
  isTraitColumn,
  RawColumn,
  RelatedColumn,
} from "src/types/visual";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { getTraitPropertyType } from "../visual/utils";
import { FilterOption } from "./constants";
import {
  EventFilterOption,
  FilterColumnOption,
  NumberOfFilterOption,
  PropertyFilterOption,
  SegmentSetFilterOption,
  TraitFilterOption,
} from "./types";
import { getSelectedColumn, buildConditionFromColumn } from "./utils";

export interface UseConditionSelect<TCondition> {
  condition?: TCondition;
  initialTab?: FilterOption;
}

export const useConditionSelect = <TCondition extends Condition>({
  condition,
  initialTab = FilterOption.All,
}: UseConditionSelect<TCondition>) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<FilterOption>(initialTab);
  const [refreshSelectedTab, setRefreshSelectedTab] = useState(true);

  const { columns, events, relationships, traits, relatedAudiences } = useQueryBuilderContext();

  const updateSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const changeFilter = (filterType: FilterOption) => {
    setFilter(filterType);
  };

  const reset = () => {
    setFilter(FilterOption.All);
    setSearch("");
  };

  const propertyOptions: PropertyFilterOption[] = useMemo(
    () =>
      columns.map(({ alias, name, column_reference, custom_type, description, type, model_name, case_sensitive }) => ({
        case_sensitive,
        description,
        label: alias || name,
        modelName: model_name,
        propertyType: (custom_type ?? type) as ColumnType,
        type: FilterOption.Property,
        value: column_reference as RawColumn | RelatedColumn,
      })),
    [columns],
  );

  const traitOptions: TraitFilterOption[] = useMemo(
    () =>
      traits
        .filter((trait) => trait.type)
        .map((trait) => ({
          label: trait.name,
          value:
            condition?.type === ConditionType.Property &&
            isRelatedColumn(condition.property) &&
            isTraitColumn(condition.property.column) &&
            condition?.property?.column.traitDefinitionId === trait.id
              ? condition.property
              : getInitialTraitColumn(trait),
          propertyType: getTraitPropertyType(trait),
          type: FilterOption.Trait,
          relatedModel: trait.relationship.to_model,
        })),
    [traits],
  );

  const eventOptions: EventFilterOption[] = useMemo(
    () =>
      events?.map(({ id: relationshipId, name, to_model: { id: eventModelId, name: eventModelName, description } }) => ({
        label: name ?? eventModelName,
        description,
        value: { eventModelId, relationshipId },
        type: FilterOption.Event,
      })),
    [events],
  );

  // Don't show related events under the options. Users should use the
  // EventConditionFilter for creating queries based on events.
  const relatedModelOptions: NumberOfFilterOption[] = useMemo(
    () =>
      relationships
        ?.filter(({ to_model: { event }, merge_columns }) => !event && !merge_columns)
        ?.map(({ id, name, to_model: { name: relatedModelName, description } }) => ({
          value: { relationshipId: id },
          label: name ?? relatedModelName,
          description,
          type: FilterOption.Relation,
        })),
    [relationships],
  );

  const audienceOptions: SegmentSetFilterOption[] = useMemo(
    () =>
      relatedAudiences?.map(({ value, ...data }) => ({
        ...data,
        value: { modelId: value },
        type: FilterOption.Audience,
      })),
    [relatedAudiences],
  );

  const allColumns = useMemo(
    () => [...propertyOptions, ...traitOptions, ...eventOptions, ...relatedModelOptions, ...audienceOptions],
    [propertyOptions, traitOptions, eventOptions, relatedModelOptions, audienceOptions],
  );

  const popoverOptions: FilterColumnOption[] = useMemo(() => {
    const columns = allColumns.filter(
      ({ label, type, description }) =>
        (filter === FilterOption.All || filter === type) &&
        (label?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
          description?.toLowerCase().includes(search.trim().toLowerCase())),
    );

    return sortBy(columns, "label");
  }, [allColumns, filter, propertyOptions, traitOptions, eventOptions, relatedModelOptions, audienceOptions, search]);

  const selectedColumn = useMemo(() => {
    if (!condition) {
      return undefined;
    }

    return getSelectedColumn(condition, allColumns);
  }, [condition, allColumns]);

  // Popover stays mounted, so may need to be triggered externally.
  useEffect(() => {
    if (refreshSelectedTab) {
      const newFilter = getSelectedColumn(condition, allColumns)?.type;

      if (newFilter) {
        setFilter(newFilter);
      }

      setRefreshSelectedTab(false);
    }
  }, [allColumns, condition, refreshSelectedTab]);

  return {
    columns: popoverOptions,
    filter,
    search,
    selectedColumn,
    onResetSelectedTab: () => setRefreshSelectedTab(true),
    onFilterChange: changeFilter,
    onReset: reset,
    onSearch: updateSearch,
    onSelectColumn: (column: FilterColumnOption) => buildConditionFromColumn(column),
  };
};
